import { ProductCardIcon, ProductCardVariant } from 'product-card/types'

import type { GetterProductCardSettings } from '../../context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'

export const getProductCardSettings: GetterProductCardSettings = (ctx) => ({
	hasSizePicker: ctx.hasOnlineSale,
	showActionIcon: ProductCardIcon.Favorite,
	shouldRenderLinkLabels: false,
	shouldRenderColorSheetButton: false,
	shouldRenderSlider: true,
	showPriceHistory: true,
	shouldRenderAddButtonAsPrimary: false,
	shouldRenderSizes: ctx.hasOnlineSale,
	shouldRenderFeatured: false,
	hasProductLabels: true,
	hasProductTags: true,
	hasColorPicker: true,
	hasSizeSheetButton: ctx.hasOnlineSale,
	variant: ProductCardVariant.General,
	feedbackTypeOnAdd: 'BagPreview',
})
