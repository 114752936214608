'use client'

import { type ReactNode, createContext, useCallback, useMemo } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import type {
	FeedbackTypeOnAdd,
	ProductCardIcon,
	ProductCardVariant,
} from '../../types'

/* eslint react-hooks/exhaustive-deps: warn */

export interface ProductCardConsumerLayoutContextProps {
	/** Show an action icon (favorites or add to bag) */
	showActionIcon: ProductCardIcon
	/** Render colors as link with label */
	shouldRenderLinkLabels: boolean
	/** Render colors sheet selector */
	shouldRenderColorSheetButton: boolean
	/** Allow slide images rendering a Slideshow */
	shouldRenderSlider: boolean
	/** If add to bag button is rendered, is rendered as primary */
	shouldRenderAddButtonAsPrimary: boolean
	/** Render size sheet */
	shouldRenderSizes: boolean
	/** Render as two columns span featured products  */
	shouldRenderFeatured: boolean
	/** Show price history */
	showPriceHistory: boolean
	/** Show product labels */
	hasProductLabels: boolean
	/** Show product tags */
	hasProductTags: boolean
	/** Show color picker */
	hasColorPicker: boolean
	/** Render size sheet button*/
	hasSizeSheetButton: boolean
	/** Show size picker */
	hasSizePicker: boolean
	/** Show as Product card variant */
	variant: ProductCardVariant
	/** Layout view type */
	layoutView?: string
	/** Disable PDP navigation link in product card image */
	disableImageLink?: boolean
	/** Feedback type on add to cart (default: BagPreview) */
	feedbackTypeOnAdd?: FeedbackTypeOnAdd
	/** On add to cart callback */
	onAddToCart?: () => void
}
export interface ProductCardSettingsContext {
	hasOnlineSale: boolean
}

export type GetterProductCardSettings = (
	ctx: ProductCardSettingsContext
) => ProductCardSettings

export type ScreenSize = 'small' | 'medium' | 'large'

export type ProductCardSettings = ProductCardConsumerLayoutContextProps
export type ResponsiveProductCardSettings = Record<
	ScreenSize,
	ProductCardSettings
>

interface ProductCardConsumerLayoutProviderProps {
	settings: ResponsiveProductCardSettings | ProductCardSettings
	children: ReactNode
	layoutView?: string
}

export const ProductCardConsumerLayoutContext =
	createContext<ProductCardSettings | null>(null)

export const ProductCardConsumerLayoutProvider = ({
	children,
	settings,
	layoutView,
}: ProductCardConsumerLayoutProviderProps) => {
	const responsiveSettings = settings as ResponsiveProductCardSettings
	const singleSettings = settings as ProductCardSettings

	const { small, medium, large } = responsiveSettings
	const { isMedium, isLargeOrGreater } = useResponsive()
	const wrapper = useCallback(
		(configurableSettings: ProductCardSettings) => ({
			...configurableSettings,
			layoutView,
		}),
		[layoutView]
	)

	const resolvedSettings = useMemo(() => {
		if (!(small && medium && large)) {
			return wrapper(singleSettings)
		}
		if (isLargeOrGreater) {
			return wrapper(large)
		}
		if (isMedium) {
			return wrapper(medium)
		}
		return wrapper(small)
	}, [
		small,
		medium,
		large,
		isLargeOrGreater,
		isMedium,
		wrapper,
		singleSettings,
	])

	return (
		<ProductCardConsumerLayoutContext.Provider value={resolvedSettings}>
			{children}
		</ProductCardConsumerLayoutContext.Provider>
	)
}
